import Vue from "vue";
import { LControl, LMap, LPopup, LTileLayer } from "vue2-leaflet";
import LGpx from "vue2-leaflet-gpx";
import MapIcon from "@/components/MapIcon/MapIcon.vue";
import Address from "@/components/Address/Address.vue";
import fullscreen from "vue-fullscreen";
import "./DetailMap.sass";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import "@/lib/colors.js";
import {
  getColorForParkingLot,
  getGoogleRouteLinkForAddress
} from "@/helpers/detailViewHelper";

Vue.use(fullscreen);

export default {
  name: "DetailMap",
  props: {
    poi: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    address: {
      type: String
    },
    widgetWidth: {
      type: Number
    },
    connectedParkingLots: {
      type: Array,
      default: []
    }
  },
  components: {
    LMap,
    LTileLayer,
    LControl,
    LPopup,
    MapIcon,
    Address,
    LGpx
  },
  data() {
    return {
      isFullScreen: false,
      isMapIconActive: false,
      url: "https://osm.eberl-online.net/tile/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      mapObject: undefined,
      gpxXml: "",
      height: 200,
      pinIconPath: "https://cdn.eberl-online.cloud/pin-icon.svg"
    };
  },
  computed: {
    street() {
      return this.poi && this.poi.address && this.poi.address.address
        ? this.poi.address.address
        : "";
    },
    routeAddress() {
      return this.poi
        ? getGoogleRouteLinkForAddress(this.poi.address)
        : undefined;
    },
    gpxUrl() {
      return this.poi?.route?.gpx?.url ?? false;
    }
  },
  mounted() {
    const m = this.$refs.myDetailMap.mapObject;
    this.mapObject = m;

    // disable zooming
    m.scrollWheelZoom.disable();

    window.addEventListener("resize", this.setMapHeight);
    this.setMapHeight();
    this.$nextTick(() => {
      this.setMapHeight();
    });
  },
  methods: {
    fullScreenToggle() {
      const elem = this.$refs.myDetailMap.$el;

      this.$fullscreen.toggle(elem, {
        wrap: false,
        callback: this.fullscreenChange
      });
    },
    fullscreenChange(isFullScreen) {
      this.isFullScreen = isFullScreen;
      const m = this.mapObject;

      // change draggable
      if (isFullScreen) {
        m.scrollWheelZoom.enable();
      } else {
        m.scrollWheelZoom.disable();
        // invalidate size
        m.invalidateSize(false);
        // re-center map
        m.panTo({ lon: this.poi.geo.lng, lat: this.poi.geo.lat });
      }
    },
    mapLoaded() {
      // invalidate size - load map correctly in some T3 instances
      this.mapObject.invalidateSize(false);
      this.loadGpxString();
    },
    gpxFitBounds(e) {
      const bounds = e.target.getBounds();
      this.$refs.myDetailMap.mapObject.fitBounds(bounds);
    },
    async loadGpxString() {
      if (this.gpxUrl) {
        try {
          this.gpxXml = (
            await axios({
              method: "get",
              url: this.gpxUrl,
              responseType: "text"
            })
          ).data;
        } catch (e) {
          console.error("can not get gpxString error:", e);
        }
      }
    },
    getColorForParkingLot,
    setMapHeight() {
      this.height = this.$refs?.mapWrapper?.clientWidth / (16 / 9);
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.setMapHeight);
  },
  watch: {
    widgetWidth() {
      // resize map accordingly and center map
      if (this.mapObject) {
        // invalidate size
        this.mapObject.invalidateSize(false);
        // re-center map
        this.mapObject.panTo({ lon: this.poi.geo.lng, lat: this.poi.geo.lat });
      }
    }
  }
};
