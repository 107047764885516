import "./PoiSub.sass";
import { apiString, href } from "@/helpers/detailViewHelper.js";
import { diffBox, difficulty, mToKm } from "@/helpers/commonHelper";
import { orderBy } from "lodash/collection";
export default {
  props: {
    pois: Array,
    filterCat: String,
    season: String
  },
  data: () => ({
    poiExpanded: [],
    poiMaxAmount: 30
  }),
  computed: {
    poisSlice() {
      let sortedPois = this.pois?.slice(0, this.poiMaxAmount);
      sortedPois = sortedPois.map(poi => {
        if (poi.state.state === "closed") poi.isOpen = false;
        return poi;
      });
      sortedPois = orderBy(sortedPois, ["isOpen", "title"], ["desc", "asc"]);
      return sortedPois;
    }
  },
  methods: {
    currentlyOpen() {
      const open = this.pois.filter(
        p =>
          (p.state.state !== "closed" && p.isOpen) || p.state.state === "open"
      );
      return open.length + "/" + this.pois.length;
    },
    expand(i) {
      this.$set(this.poiExpanded, i, !this.poiExpanded[i]);
    },
    diffBox,
    difficulty,
    iconColorBase(poi) {
      let color = "icon_open_green";
      switch (poi?.state?.state) {
        case "highWorkload":
          color = "icon_yellow";
          break;
        case "capacityExhausted":
          color = "icon_red";
          break;
        case "closed":
          color = "icon_red";
          break;
        case "openingHours":
          if (!poi.isOpen && poi?.calculatedOpeningHours?.length) {
            color = "icon_red";
          }
          break;
        case "partiallyOpen":
          color = "icon_yellow";
          break;
        case "":
          color = "icon_blue";
      }
      return color;
    },
    iconBase(poi) {
      const isParking = this.filterCat === "parkplaetze";
      let icon = "";
      switch (poi?.state?.state) {
        case "open":
          icon = "mdi-checkbox-blank-circle";
          if (isParking) icon = "mdi-alpha-p-box";
          break;
        case "openingHours":
          if (poi.isOpen) {
            icon = "mdi-checkbox-blank-circle";
            if (isParking) icon = "mdi-alpha-p-box";
          } else {
            if (poi?.calculatedOpeningHours?.length) {
              icon = "mdi-minus-circle";
              if (isParking) icon = "mdi-alpha-p-box";
            }
          }
          break;
        case "closed":
          icon = "mdi-minus-circle";
          if (isParking) icon = "mdi-alpha-p-box";
          break;
        case "partiallyOpen":
          icon = "mdi-circle-half-full";
          break;
        case "highWorkload":
          icon = "mdi-alpha-p-box";
          break;
        case "capacityExhausted":
          icon = "mdi-alpha-p-box";
          break;
        case "avalancheLock":
          icon = "mdi-checkbox-blank-circle-outline";
          break;
      }
      if (icon === "" && poi.isOpen && poi?.state?.state !== "close") {
        icon = "mdi-checkbox-blank-circle";
      } else if (icon === "") {
        icon = "mdi-checkbox-blank-circle-outline";
      }
      return icon;
    },
    href,
    apiString,
    navigate(idOrSlug) {
      const href = this.href(idOrSlug);
      window.open(href, "_blank");
    },
    hasFloodlight(p) {
      return p.state?.floodlightAt?.some(f => !!f);
    },
    floodlight(p) {
      if (!this.hasFloodlight(p)) {
        return "";
      }

      let floodlight = "";
      let floodlightArr = [...new Set(p.state.floodlightAt)]; //array unique
      if (floodlightArr.length > 0) {
        floodlightArr = floodlightArr.map(day =>
          day
            .replace("mo", "1Mo")
            .replace("tu", "2Di")
            .replace("we", "3Mi")
            .replace("th", "4Do")
            .replace("fr", "5Fr")
            .replace("sa", "6Sa")
            .replace("su", "7So")
        );
        floodlightArr.sort();

        //build connection days week-overview fe. Mo-Fr,So
        let connectionDays = [];
        let firstDayOfSequence = "";
        // let lastDayOfSequence = "";
        let lastNum = 1;
        floodlightArr.forEach(d => {
          let dayNum = parseInt(d);
          if (dayNum - 1 === lastNum) {
            connectionDays[connectionDays.length - 1] = (
              firstDayOfSequence +
              "-" +
              d
            ).replace(/\d/g, "");
          } else {
            connectionDays.push(d.replace(/\d/g, ""));
            firstDayOfSequence = d;
          }
          lastNum = dayNum;
        });
        connectionDays = connectionDays.filter(d => !!d); //remove empty days
        console.info("connectionDays", connectionDays);
        floodlight = connectionDays.join(", ").replace("Mo-So", "täglich");
        return (
          `<b>Flutlicht:</b> ` +
          floodlight +
          " " +
          p?.state?.floodlightFrom +
          " - " +
          p?.state?.floodlightUntil +
          " " //keep this space
        );
      }
    },
    seasonDescription(p) {
      let description = p?.description?.description;
      if (this.season === "winter" && p?.description?.seasonalDescription)
        description = p?.description?.seasonalDescription;
      if (description?.trim().length) {
        return `
        <b>Beschreibung:</b>
        <span class="eo-sub-description">${description}</span>`;
      }
    },
    mToKm
  }
};
