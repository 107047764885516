import "./TourDetails.scss";
import { getDifficultyText } from "@/helpers/commonHelper.js";
import TourDetailsRating from "@/components/TourDetailsRating/TourDetailsRating.vue";

export default {
  props: ["state", "route"],
  components: {
    TourDetailsRating
  },
  methods: {
    getDifficultyText
  }
};
