var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-100 pb-3"},[(
      _vm.settings &&
        _vm.settings.apiKey &&
        _vm.settings.apiKey.displayCurrentlyOpen &&
        _vm.poi.state &&
        ((_vm.poi.state.state === 'openingHours' && _vm.poi.isOpen && _vm.closesIn) ||
          _vm.poi.state.state !== '')
    )?_c('div',{staticClass:"color-main-blue pt-3"},[_c('PoiOpenClosed',{staticClass:"eopoi-app-detail-date mb-2",attrs:{"poi":_vm.poi}}),(
        _vm.poi &&
          _vm.checkOpeningHours(_vm.poi.openingHours) &&
          _vm.poi.state.state === 'openingHours'
      )?_c('a',{staticClass:"more-dates-button mt-3 ml-3",on:{"click":_vm.moveToDates}},[_vm._v("Öffnungszeiten ")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }