import "./TourBestSeason.scss";

export default {
  props: ["bestSeason"],
  data: () => ({
    months: [
      { id: "jan", title: "Januar" },
      { id: "feb", title: "Februar" },
      { id: "mar", title: "März" },
      { id: "apr", title: "April" },
      { id: "mai", title: "Mai" },
      { id: "jun", title: "Juni" },
      { id: "jul", title: "Juli" },
      { id: "aug", title: "August" },
      { id: "sep", title: "September" },
      { id: "oct", title: "Oktober" },
      { id: "nov", title: "November" },
      { id: "dec", title: "Dezember" }
    ]
  })
};
