import PoiSub from "@/components/PoiSub/PoiSub";
export default {
  extends: PoiSub,
  methods: {
    snowTexture(p) {
      const snowTextureItems = [
        { id: "oldSnow", title: "Altschnee" },
        { id: "solidOldSnow", title: "Fester Altschnee" },
        { id: "moistOldSnow", title: "Feuchtnasser Altschnee" },
        { id: "powderSnow", title: "Pulverschnee" },
        { id: "mashSnow", title: "Sulzschnee" },
        { id: "cardBoardSnow", title: "Pappschnee" },
        { id: "maschineSnow", title: "Maschinenschnee" },
        { id: "drySnow", title: "Trockener Neuschnee" },
        { id: "boundFreshSnow", title: "Gebundener Neuschnee" },
        { id: "frozenSnow", title: "Harsch" },
        { id: "firnSnow", title: "Firnschnee" }
      ];

      return (
        snowTextureItems.find(st => st.id === p.state?.snowTexture)?.title ||
        "k.A."
      );
    }
  }
};
