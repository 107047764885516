import { Line } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from "chart.js";

ChartJS.register(Filler, LineElement, LinearScale, CategoryScale, PointElement);
export default {
  name: "LineChart",
  extends: Line,
  props: ["chartData", "chartOptions"],
  data: () => ({
    minWidth: 600
  }),
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  methods: {
    onResize() {
      const chart = this.getCurrentChart();
      if (!chart) {
        return;
      }
      const container = chart.canvas?.parentNode;
      if (!container) {
        return;
      }
      const containerWidth = container.getBoundingClientRect().width;
      const containerOverflows = containerWidth <= this.minWidth;

      this.$emit("setChartContainerOverflows", containerOverflows);
      chart.resize(Math.max(containerWidth, this.minWidth), chart.height);
    }
  }
};
