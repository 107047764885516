const regionSearchSubjects = [
  {
    title: "Lifte/Bahnen",
    catUrl: "bergbahnen-skilifte",
    icon: "$cableWay",
    active: false,
    season: "all"
  },
  {
    title: "Loipen",
    catUrl: "langlauf-skating",
    icon: "$crossCountrySkiing",
    active: false,
    season: "winter"
  },
  {
    title: "Skifahren",
    catUrl: "skifahrer",
    icon: "$skiingPeople",
    active: false,
    season: "winter"
  },
  {
    title: "Pisten",
    catUrl: "pisten",
    icon: "$slope",
    active: false,
    season: "winter"
  },
  {
    title: "Rodeln",
    catUrl: "rodeln",
    icon: "$sledding",
    active: false,
    season: "all"
  },
  {
    title: "Hütten",
    catUrl: "alpen-huetten",
    icon: "$hutAlt",
    active: false,
    season: "all"
  },
  {
    title: "Essen & Trinken",
    catUrl: "essen-und-trinken",
    icon: "$facilities",
    active: false,
    season: "all"
  },
  {
    title: "Badesee",
    catUrl: "badespass",
    icon: "$swimming",
    active: false,
    season: "summer"
  },
  {
    title: "Wandern",
    catUrl: "wege",
    icon: "$hiking",
    active: true,
    season: "all"
  },
  {
    title: "Winterwandern",
    catUrl: "winterwandern",
    icon: "$snowShoeing",
    active: false,
    season: "winter"
  },
  {
    title: "Einrichtungen",
    catUrl: "Freizeit-und-Sport",
    icon: "$facilities",
    active: false,
    season: "all"
  },
  {
    title: "Einkaufen",
    catUrl: "einkaufen",
    icon: "$facilities",
    active: false,
    season: "all"
  },
  {
    title: "Gesundheit",
    catUrl: "gesundheit",
    icon: "$facilities",
    active: false,
    season: "all"
  },
  {
    title: "Service",
    catUrl: "service",
    icon: "$facilities",
    active: false,
    season: "all"
  },
  {
    title: "Fahrrad",
    catUrl: "fahrrad",
    icon: "$bike",
    active: false,
    season: "summer",
    style: "min-width:44px"
  },
  {
    title: "Schlittschuh",
    catUrl: "schlittschuh",
    icon: "$iceSkate",
    active: false,
    season: "winter"
  },
  {
    title: "Parkplätze",
    catUrl: "parkplaetze",
    icon: "mdi-alpha-p-box-outline",
    active: false,
    season: "all"
  }
];
export default regionSearchSubjects;
