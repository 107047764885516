import "./PoiRegionSubjects.sass";
import regionSearchSubjects from "@/lib/regionSearchSubjects";
import { getDifficultyText } from "@/helpers/commonHelper";
import PoiSubHike from "@/components/PoiSubHike/PoiSubHike.vue";
import PoiSubSledge from "@/components/PoiSubSledge/PoiSubSledge.vue";
import PoiSubSlope from "@/components/PoiSubSlope/PoiSubSlope.js";
import PoiSubTrail from "@/components/PoiSubTrail/PoiSubTrail.vue";
import PoiSubParking from "@/components/PoiSubParking/PoiSubParking.vue";
import PoiSubLake from "@/components/PoiSubLake/PoiSubLake.vue";
import PoiSubHut from "@/components/PoiSubHut/PoiSubHut.vue";
import PoiSubLift from "@/components/PoiSubLift/PoiSubLift.vue";
import PoiSubFreeTime from "@/components/PoiSubFreeTime/PoiSubFreeTime.vue";
import flatten from "lodash/flatten";
import _ from "lodash";

export default {
  components: {
    PoiSubHike,
    PoiSubSledge,
    PoiSubSlope,
    PoiSubTrail,
    PoiSubParking,
    PoiSubLake,
    PoiSubHut,
    PoiSubLift,
    PoiSubFreeTime
  },
  props: {
    poi: Object,
    settings: Object
  },
  data: () => ({
    season: "summer",
    filterCat: "wege",
    page: 1,
    hasWinterPois: true,
    hasSummerPois: true,
    searchSubjects: regionSearchSubjects,
    freeTimeCategories: [
      "Freizeit-und-Sport",
      "essen-und-trinken",
      "service",
      "gesundheit",
      "einkaufen"
    ]
  }),
  computed: {
    mappingFields() {
      return this.$store.state.allMappingFields;
    },
    filteredSubjects() {
      const icons = [];
      this.searchSubjects.forEach(subject => {
        if (subject.season !== "all") {
          if (this.season !== subject.season) {
            return;
          }
        }
        const catGroup = this.categoryGroups.find(cg => {
          return cg.urlPart === subject.catUrl;
        });
        let hasPois = false;
        if (catGroup) {
          hasPois = this.seasonPois.some(poi => {
            return poi.categoryGroup.includes(catGroup._id) && !poi.hidden;
          });
        }
        if (hasPois) icons.push(subject);
      });
      return icons;
    },
    paginatedRegionPois() {
      const maxItems = 10;
      if (this.filteredRegionPoisArray?.length < maxItems) {
        return this.filteredRegionPoisArray;
      } else {
        let from = (this.page - 1) * maxItems;
        let to = this.page * maxItems;
        to =
          this.filteredRegionPoisArray?.length < to
            ? this.filteredRegionPoisArray?.length
            : to;
        return this.filteredRegionPoisArray?.slice(from, to);
      }
    },
    categories() {
      return flatten(this.categoryGroups.map(ct => ct.categories));
    },
    filteredRegionPoisArray() {
      const rPois = this.seasonPois;
      const catGroup = this.categoryGroups.find(cg => {
        return cg.urlPart === this.filterCat;
      });
      if (!catGroup?._id) {
        return [];
      }
      //map categoryGroups for parentCategories and mappingFields
      rPois.forEach(poi => {
        if (poi.category?.parentCategory)
          poi.category = this.categories.find(
            cat => cat._id === poi.category.parentCategory
          );
        let categoryArray = [
          this.categories.find(cat => cat._id === poi.category)
        ];
        if (!Array.isArray(poi.categoryGroup))
          poi.categoryGroup = [poi.categoryGroup];
        if (poi.additionalCategories.length)
          poi.additionalCategories.forEach(category =>
            categoryArray.push(
              this.categories.find(cat => cat._id === category)
            )
          );
        categoryArray.forEach(category => {
          if (category?.mappingField.length) {
            category.mappingField.forEach(catMField => {
              const mappingField = this.mappingFields.find(
                mField => mField._id === catMField
              );
              if (mappingField)
                poi.categoryGroup.push(
                  this.mapSubPoisCategoryGroup(mappingField)
                );
            });
            poi.categoryGroup = poi.categoryGroup.filter(
              group => group?.length
            );
            poi.categoryGroup = _.uniq(poi.categoryGroup);
          }
        });
      });
      return rPois.filter(poi => {
        return poi.categoryGroup.includes(catGroup._id) && !poi.hidden;
      });
    },
    seasonPois() {
      return this.seasonPoisBySeason(this.season).filter(
        poi => poi._id !== this.poi._id
      );
    },
    regionPois() {
      return this.$store.state.regionPois;
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    }
  },
  methods: {
    updateSeason(season) {
      this.season = season ? season : this.season;
      this.filterCategory(this.filteredSubjects[0]?.catUrl);
    },
    filterCategory(catUrl) {
      this.page = 1;
      this.filterCat = catUrl;
      this.searchSubjects.map(sf => {
        sf.active = sf.catUrl === catUrl;
      });
    },
    seasonPoisBySeason(season) {
      const seasonFeatures = this.settings.export.seasonFeatures;
      let summerId = seasonFeatures.summer;
      let winterId = seasonFeatures.winter;
      const rPois = this.$store.state.regionPois;

      return rPois.filter(poi => {
        if (!poi?.features) {
          return false;
        }
        return (
          // contains correct season
          poi.features.includes(season === "summer" ? summerId : winterId) ||
          // contains no season == contains both seasons
          (!poi.features.includes(summerId) && !poi.features.includes(winterId))
        );
      });
    },
    filterPoisBySeasonCategories(seasonPois, seasonType) {
      const urlParts = [];
      this.searchSubjects.forEach(ssub => {
        if (ssub.season === "all" || ssub.season === seasonType) {
          urlParts.push(ssub.catUrl);
        }
      });
      const catGroups = [];
      this.categoryGroups.forEach(cg => {
        if (urlParts.includes(cg.urlPart)) {
          catGroups.push(cg._id);
        }
      });
      let pois = [];
      seasonPois.forEach(poi => {
        catGroups.forEach(group => {
          if (poi.categoryGroup.includes(group)) pois.push(poi);
        });
      });
      return pois;
    },
    mapSubPoisCategoryGroup(mappingField) {
      switch (mappingField.title) {
        case "Badeseen":
          return "6049fb36dcd565001178b35c";
        case "Bergbahnen":
          return "6049fb2adcd565001178b35a";
        case "Hütten & Alpen":
          return "6075334c1d240a001182a36d";
        case "Freizeit & Aktivität":
          return "5fc7548611f9a80011aea5a1";
        case "Parkplätze":
          return "6049fb3cdcd565001178b35e";
        case "Skifahren":
          return "60b49f6f9d71c30012efbe55";
        case "Langlaufen":
          return "60753148e04a220012701ffb";
        case "Winterwandern":
          return "6049be79dcd565001178b336";
        case "Rodeln":
          return "60753168e04a220012702000";
        case "Orte / Destinationen":
          return "6049fb57dcd565001178b360";
        case "Service":
          return "5fc7602811f9a80011aea5d7";
        case "Einkaufen":
          return "5fc74cc711f9a80011aea57f";
        case "Gesundheit":
          return "5fc75eba89482600123e3624";
        case "Essen & Trinken":
          return "5fc745cf11f9a80011aea55c";
        default:
          return "";
      }
    },
    hideEmptySeason() {
      this.hasWinterPois = this.filterPoisBySeasonCategories(
        this.seasonPoisBySeason("winter"),
        "winter"
      )?.length;
      this.hasSummerPois = this.filterPoisBySeasonCategories(
        this.seasonPoisBySeason("summer"),
        "summer"
      )?.length;
      if (this.season === "winter" && !this.hasWinterPois) {
        this.season = "summer";
        this.updateSeason();
      }
      if (this.season === "summer" && !this.hasSummerPois) {
        this.season = "winter";
        this.updateSeason();
      }
    },
    getDifficultyText
  },
  async mounted() {
    this.season = this.currentSeason.repeat(1); //avoid reference
    await this.$store.dispatch("getAllMappingFieldsForDetailView");
    await this.$store.dispatch("getRegionPois", {
      poiId: this.poi._id
    });
    if (this.poi?.relatedPois?.length) {
      await this.$store.dispatch("getRelatedPois", {
        relatedPoiIds: this.poi.relatedPois
      });
    }
    this.hideEmptySeason();
    if (this.filteredSubjects?.length) {
      this.updateSeason();
    }
  }
};
