import PoiSub from "@/components/PoiSub/PoiSub";
import { getColorForParkingLot } from "@/helpers/detailViewHelper";
export default {
  extends: PoiSub,
  methods: {
    getColorForParkingLot,
    iconColorNoClass(p) {
      //avoid white on white
      return getColorForParkingLot(p).replace("white", "gray");
    },
    icon(poi) {
      if (poi?.isOpen) return "mdi-alpha-p-box";
      return "mdi-minus-circle";
    },
    paymentInfo(p) {
      return p.features
        ?.find(f => f._id === "6076e77f8d6dd80012e80283")
        ?.value?.join(", ");
    },
    cost(p) {
      let price = p.features?.find(f => f._id === "6076e7d88d6dd80012e8028c")
        ?.value;
      if (!price) return false;

      return parseFloat(price)
        ?.toFixed(2)
        ?.toString()
        ?.replace(".", ",");
    },
    maxDuration(p) {
      return p.features
        ?.find(f => f._id === "6076e7e28d6dd80012e8028e")
        ?.value?.replace(".", ",");
    },
    parkTimes(p) {
      return p.features?.find(f => f._id === "6076e7ce8d6dd80012e8028a")?.value;
    },
    chargeable(p) {
      return !!p.features?.find(f => f === "6076e7478d6dd80012e80279");
    },
    publicTransport(p) {
      return !!p.features?.find(f => f === "6076e7fa8d6dd80012e80292");
    },
    disabledSpots(p) {
      return !!p.features?.find(f => f === "6076e7fa8d6dd80012e80292");
    }
  }
};
