<script>
export default {
  name: "PricePackesMixin",
  methods: {
    // return true if price package has any data
    pricePackageContainsData(pricePackage) {
      return (
        pricePackage &&
        (pricePackage.servicesDescription ||
          pricePackage.serviceUpload ||
          pricePackage.serviceLink ||
          (pricePackage.services.length && pricePackage.services[0].name))
      );
    }
  },
  computed: {
    hasPricePackage() {
      if (this.poi && this.poi.pricePackages && this.poi.pricePackages.length) {
        if (this.poi.pricePackages.length > 1) return true; // multiple packages
        return this.pricePackageContainsData(this.poi.pricePackages[0]);
      }
      return false;
    },
    isFreeEntrance() {
      return this.poi && this.poi.freeEntrance;
    },
    hasGeneralPrices() {
      return (
        this.poi &&
        this.poi.pricePackages &&
        this.poi.pricePackages.length &&
        this.pricePackageContainsData(this.poi.pricePackages[0])
      );
    }
  }
};
</script>
