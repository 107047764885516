import "./DetailViewHeader.scss";
import PoiPicture from "@/components/PoiPicture/PoiPicture.vue";
import PoiPictureSlider from "@/components/PoiPictureSlider/PoiPictureSlider.vue";
import DetailMap from "@/components/DetailMap/DetailMap.vue";
import Address from "@/components/Address/Address.vue";
import PoiOpenClosed from "@/components/PoiOpenClosed/PoiOpenClosed.vue";
import PoiDetailOpenState from "@/components/PoiDetailOpenState/PoiDetailOpenState.vue";
import * as detailViewHelper from "@/helpers/detailViewHelper.js";
import PictureMixin from "@/mixins/PictureMixin.vue";
import ColorsMixin from "@/mixins/ColorsMixin.vue";
import { checkStep } from "@/helpers/detailViewHelper.js";
import flatten from "lodash/flatten";
import Attach from "@/components/Attach/Attach.vue";
import { opChain } from "@/mixins/globalMethods";

export default {
  props: [
    "poi",
    "poiImages",
    "showNotepad",
    "widgetWidth",
    "categories",
    "mainPois",
    "refreshMapValue",
    "myURL",
    "isFavorite",
    "listUri",
    "summaryUri",
    "settings",
    "socialMediaKeys",
    "openPanel",
    "connectedParkingLots",
    "currentSeason"
  ],
  components: {
    PoiOpenClosed,
    DetailMap,
    PoiPictureSlider,
    PoiPicture,
    Address,
    PoiDetailOpenState,
    Attach
  },
  mixins: [ColorsMixin, PictureMixin],
  methods: {
    ...detailViewHelper,
    toggleMobileMap() {
      this.mobileMap = !this.mobileMap;
      // widgetWidth größe ändern um karte zu refreshen (graue flächen verhindern)
      this.$emit("refreshMapValue", this.refreshMapValue + 0.01);
      // scroll up
      this.$vuetify.goTo(300);
    },
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    moveToFilteredList(category) {
      const categories = flatten(
        this.categoryGroups?.map(ct => ct?.categories)
      );
      const cat = categories?.find(cat => cat.title === category);
      const { _id: catId, mappingField } = cat ?? {};
      if (mappingField)
        this.$store.commit("SET_SELECTED_MAPPING_FIELD", mappingField);
      this.$store.commit("SET_SELECTED_CATEGORIES", [catId]);
      this.$router.push(this.listUri);
    },
    opChain
  },
  data: () => ({
    mobileMap: false,
    socialShareDialog: false
  }),
  computed: {
    mainCategoryLabel() {
      if (this.poi) {
        if (
          this.poi.category &&
          this.$store.state.labelsById?.[this.poi.category]
        ) {
          return this.$store.state.labelsById?.[this.poi.category];
        }
      }
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    contactAddress() {
      return this.poi?.contact?.showContact ?? "hidden";
    },
    isOverviewPoi() {
      return !!this.$store.state?.regionPois?.length;
    },
    showSummary() {
      return !!this.settings.apiKey?.showSummary;
    },
    hideBreadcrumb() {
      return !!this.settings.apiKey?.hideBreadcrumb;
    }
  }
};
