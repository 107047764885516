import PoiSub from "@/components/PoiSub/PoiSub";
import moment from "moment";
export default {
  extends: PoiSub,
  methods: {
    moment,
    waterQualityLabel(p) {
      let waterQualityItems = [
        { id: "excellent", title: "Ausgezeichnet" },
        { id: "good", title: "Gut" },
        { id: "sufficient", title: "Ausreichend" },
        { id: "insufficient", title: "Mangel" }
      ];
      return (
        waterQualityItems.find(wq => wq.id === p.state?.waterQuality)?.title ||
        "k.A."
      );
    }
  }
};
