import { fixUrl, mToKm } from "@/helpers/commonHelper";
import PictureMixin from "@/mixins/PictureMixin.vue";
import ColorsMixin from "@/mixins/ColorsMixin.vue";
import Attach from "@/components/Attach/Attach.vue";
import PoiRegionSubjects from "@/components/PoiRegionSubjects/PoiRegionSubjects.vue";
import PoiDetailStatus from "@/components/PoiDetailStatus/PoiDetailStatus.vue";
import PoiOpenClosed from "@/components/PoiOpenClosed/PoiOpenClosed.vue";
import ExcursionTicker from "@/components/ExcursionTicker/ExcursionTicker.vue";
import TourBestSeason from "@/components/TourBestSeason/TourBestSeason.vue";
import TourDescriptionTabs from "@/components/TourDescriptionTabs/TourDescriptionTabs.vue";
import TourDetails from "@/components/TourDetails/TourDetails.vue";
import TourDetailStatus from "@/components/TourDetailStatus/TourDetailStatus.vue";
import TourDetailTip from "@/components/TourDetailTip/TourDetailTip.vue";
import Features from "@/components/Features/Features.vue";
import SocialIcons from "@/components/SocialIcons/SocialIcons.vue";
import RelatedPoisTeaser from "@/components/RelatedPoisTeaser/RelatedPoisTeaser.vue";
import {
  checkStep,
  getColorForParkingLot,
  navigate,
  onFavoritesClick,
  downloadGpx
} from "@/helpers/detailViewHelper.js";
import "./TourDetailView.scss";

export default {
  props: [
    "poi",
    "isCompactView",
    "shortDescription",
    "featureList",
    "highlightFeatures",
    "currentMessage",
    "currentSeason",
    "description",
    "listUri",
    "mainPois",
    "settings",
    "openPanel",
    "connectedParkingLots",
    "myURL",
    "isFavorite",
    "socialMediaKeys",
    "showNotepad",
    "isOutdoorActiveTour",
    "outdoorActiveTourId"
  ],
  components: {
    Attach,
    PoiRegionSubjects,
    PoiDetailStatus,
    PoiOpenClosed,
    ExcursionTicker,
    TourBestSeason,
    TourDescriptionTabs,
    TourDetails,
    TourDetailStatus,
    TourDetailTip,
    Features,
    SocialIcons,
    RelatedPoisTeaser
  },
  mounted() {
    if (this.isOutdoorActiveTour && this.outdoorActiveTourId) {
      this.addRobotsMetaTag();
      this.addOutdoorActiveTourDataDownloadButton();
    }
  },
  mixins: [ColorsMixin, PictureMixin],
  methods: {
    onFavoritesClick,
    fixUrl,
    mToKm,
    getColorForParkingLot,
    navigate,
    downloadGpx,
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    linkToParkingLot(slug) {
      if (slug) window.location.assign(slug);
    },
    addRobotsMetaTag() {
      if (!this.poi.importMetaData?.robots) {
        return;
      }
      const meta = document.createElement("meta");
      meta.setAttribute("robots", this.poi.importMetaData.robots);
      document.head.appendChild(meta);
    },
    addOutdoorActiveTourDataDownloadButton() {
      window.oa.pdfDialog(
        this.outdoorActiveTourId,
        document.getElementById("download_outdoor_active_tour_data_pdf_btn")
      );
    }
  },
  data: () => ({
    readMore: false,
    socialShareDialog: false
  }),
  computed: {
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    hideFeatureSummer() {
      return this.settings.apiKey.hideFeatureSummer;
    },
    hideFeatureWinter() {
      return this.settings.apiKey.hideFeatureWinter;
    },
    cOpenPanel: {
      get() {
        return this.openPanel;
      },
      set(value) {
        this.$emit("openPanelChanged", value);
      }
    }
  }
};
