import { checkStep } from "@/helpers/detailViewHelper";
import PoiOpenClosed from "@/components/PoiOpenClosed/PoiOpenClosed.vue";
import "./PoiDetailStatus.scss";
import {
  mToKm,
  getSnowTexture,
  getWeatherCondition,
  getSight,
  toDate
} from "@/helpers/commonHelper";
import ColorsMixin from "@/mixins/ColorsMixin";

export default {
  props: ["poi", "settings", "openPanel"],
  mixins: [ColorsMixin],
  data() {
    return {
      ...this.poi?.state
    };
  },
  components: {
    PoiOpenClosed
  },
  computed: {
    isSummerSeason() {
      return this.settings?.apiKey?.season?.includes("summer");
    },
    isOverviewPoi() {
      return !!this.$store.state?.regionPois?.length;
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    }
  },
  methods: {
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    getWaterQualityText(waterQuality) {
      switch (waterQuality) {
        case "good":
          return "Gut";
        case "sufficient":
          return "Ausreichend";
        case "insufficient":
          return "Mangel";
      }
    },
    getAvalancheLevel(key) {
      switch (key) {
        case "low":
          return "1 Gering";
        case "moderate":
          return "2 Mäßig";
        case "considerable":
          return "3 Erheblich";
        case "high":
          return "4 Groß";
        case "veryHigh":
          return "5 Sehr Groß";
      }
    },
    getAvalancheLevelColor(key) {
      switch (key) {
        case "low":
        case "moderate":
          return this.$mainBlue;
        case "considerable":
          return this.$mainYellow;
        case "high":
        case "veryHigh":
          return this.$mainRed;
      }
    },
    mToKm,
    getSnowTexture,
    getWeatherCondition,
    getSight,
    toDate
  }
};
