import PictureMixin from "@/mixins/PictureMixin.vue";
import "./PoiPictureSlider.sass";
import { fixUrl, checkForImage } from "@/helpers/commonHelper";

export default {
  name: "PoiPictureSlider",
  props: {
    poi: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  mixins: [PictureMixin],
  data: () => ({
    counter: 0,
    maxWidth: 1920,
    maxHeight: 600,
    overlay: false
  }),
  mounted() {
    this.onResize();
  },
  created() {
    window.addEventListener("keydown", this.onKeydown.bind(this));
  },
  destroyed() {
    window.removeEventListener("keydown", this.onKeydown.bind(this));
  },
  computed: {
    poiImages() {
      let pictures = this.poi ? this.camAndSeasonImages(this.poi) : [];
      pictures.forEach(pic => {
        if (!checkForImage(pic)) {
          this.picLink = pic.link || pic.url;
        }
      });
      return pictures.filter(pic => checkForImage(pic));
    },
    isFallbackPicture() {
      return this.poiImages.length === 0;
    }
  },
  methods: {
    resizePicture(url) {
      const isOutdoorActiveImage = url.includes("img.oastatic");
      if (isOutdoorActiveImage) {
        // Use same values as in PoiPicture for caching and to prevent requesting bigger sizes as the original is due unknown dimensions.
        const widhtOutdoorActive = 1280;
        const heightOutdoorActive = 720;
        return url.replace(
          "/img/",
          `/img/${widhtOutdoorActive}/${heightOutdoorActive}/`
        );
      }
      const width = this.maxWidth || 1920;
      const height = this.maxHeight || 600;
      const biggest = width > height ? width : height;
      return url.replace(/\/pois\//, `/t/${biggest}/pois/`);
    },
    onResize() {
      this.maxWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      this.maxHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
    },

    onKeydown($event) {
      // handle keys
      if (this.overlay) {
        switch ($event.keyCode) {
          case 39: // right arrow
            this.counter++;
            break;
          case 37: // left arrow
            if (this.counter === 0)
              this.counter =
                this.filterSeasonImages(this.poi.pictures).length - 1;
            else this.counter--;
            break;
          case 27: // escpae
            this.overlay = false;
            break;
        }
      }
    },
    fixUrl
  }
};
