import moment from "moment";
import PoiOpenClosed from "@/components/PoiOpenClosed/PoiOpenClosed.vue";
import * as detailViewHelper from "@/helpers/detailViewHelper";

export default {
  props: ["poi", "settings", "openPanel"],
  data() {
    return {
      ...this.poi?.state
    };
  },
  components: {
    PoiOpenClosed
  },
  computed: {
    cOpenPanel: {
      get() {
        return this.openPanel;
      },
      set(value) {
        this.$emit("openPanelChange", value);
      }
    }
  },
  methods: {
    ...detailViewHelper,
    moveToDates() {
      detailViewHelper.moveToDates.bind(this).call();
    },
    closesIn() {
      // if fuzzy closing time or always open => skip
      if (
        this.poi.fuzzyOpenCloseTime ||
        (this.poi.openingHours && this.poi.openingHours.freeAccess)
      )
        return undefined;
      return moment
        .duration(this.poi.closesIn, "s")
        .locale("de")
        .humanize(true);
    }
  }
};
