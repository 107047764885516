var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.poi && _vm.regionPois && _vm.regionPois.length)?_c('div',{attrs:{"id":"poi-region-subjects"}},[_c('v-row',{staticClass:"mt-xl-6 mt-lg-6 mt-md-3 mt-sm-3 ml-8 mr-8 mb-xl-4 mb-lg-4 mb-md-2 mb-sm-2"},[_c('v-col',{attrs:{"cols":"2","xl":"2","lg":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"bg-white rounded"},[(_vm.hasSummerPois)?_c('v-btn',{staticClass:"px-md-15 px-sm-10 white--text",class:{
                opacity05: _vm.season !== 'summer',
                bolder: _vm.season === 'summer'
              },attrs:{"x-large":"","color":"primary","elevation":"4"},on:{"click":function($event){return _vm.updateSeason('summer')}}},[_vm._v("Sommer")]):_vm._e()],1),_c('div',{staticClass:"bg-white ml-4 rounded"},[(_vm.hasWinterPois)?_c('v-btn',{staticClass:"px-md-15 px-sm-10 white--text bold",class:{
                opacity05: _vm.season !== 'winter',
                bolder: _vm.season === 'winter'
              },attrs:{"x-large":"","color":"primary","elevation":"4"},on:{"click":function($event){return _vm.updateSeason('winter')}}},[_vm._v("Winter")]):_vm._e()],1)])],1)],1)],1),_c('div',{staticClass:"subjectIcons d-flex justify-sm-center justify-md-center justify-lg-center overflow-x-auto"},_vm._l((_vm.filteredSubjects),function(subject){return _c('div',{key:subject.title,staticClass:"sIcon text-center ma-3 cursor-pointer",on:{"click":function($event){return _vm.filterCategory(subject.catUrl)}}},[_c('v-icon',{class:{
          'black--text': subject.active,
          icon_blue: !subject.active,
          hover_color: !subject.active
        },style:(subject.style),attrs:{"large":""}},[_vm._v(_vm._s(subject.icon))]),_c('span',{staticClass:"icon_blue hover-color d-block",class:{ 'black--text': subject.active }},[_vm._v(_vm._s(subject.title))])],1)}),0),_c('v-row',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"region-pois flex-grow-1 ml-2"},[(_vm.filterCat === 'wege' || _vm.filterCat === 'fahrrad')?_c('PoiSubHike',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'rodeln')?_c('PoiSubSledge',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'pisten')?_c('PoiSubSlope',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'langlauf-skating')?_c('PoiSubTrail',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'parkplaetze')?_c('PoiSubParking',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'badespass')?_c('PoiSubLake',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'alpen-huetten')?_c('PoiSubHut',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.filterCat === 'bergbahnen-skilifte')?_c('PoiSubLift',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e(),(_vm.freeTimeCategories.includes(_vm.filterCat))?_c('PoiSubFreeTime',{attrs:{"filterCat":_vm.filterCat,"pois":_vm.filteredRegionPoisArray,"season":_vm.season}}):_vm._e()],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }