import "./TourDetailsRating.scss";

export default {
  data: () => ({
    maxValue: 6,
    iconSize: 20
  }),
  props: {
    points: Number
  }
};
