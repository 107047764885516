import PoiSub from "@/components/PoiSub/PoiSub";
export default {
  extends: PoiSub,
  computed: {
    liftsGroup() {
      return this.$store.state.categories.find(
        catType => catType.urlPart === "Freizeit-und-Sport"
      );
    }
  },
  methods: {
    categoryLabel(p) {
      const cat = this.liftsGroup?.categories?.find(
        cat => cat._id === p.category
      );
      return cat?.title || "";
    }
  }
};
