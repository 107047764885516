import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import "./PanoramaMaps.scss";
export default {
  props: ["poi", "panoramaMaps", "textColor"],
  data: () => ({
    panoramaMapsOverlay: false,
    panoramaMapsCounter: 0
  }),
  components: {
    VueperSlides,
    VueperSlide
  },
  computed: {},
  methods: {
    closeOverlay($event) {
      if ($event?.path?.[0]?.className?.includes("overlay")) {
        this.panoramaMapsOverlay = false;
      }
    }
  }
};
