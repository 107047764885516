import PricePackagesMixin from "@/mixins/PricePackagesMixin";
import PoiPricesPiece from "@/components/PoiPricesPiece/PoiPricesPiece.vue";

export default {
  name: "PoiPrices",
  components: { PoiPricesPiece },
  props: {
    poi: {
      type: Object,
      required: true
    }
  },
  mixins: [PricePackagesMixin],
  data: () => ({
    tab: null
  }),
  created() {
    // set winter if it is winter
    this.tab = this.isWinter ? "eopoi-dv-prices-tab-winter" : "";
  },
  computed: {
    summerPrices() {
      if (this.poi && this.poi.pricePackages) {
        return this.poi.pricePackages.filter(
          (pricePackage, idx) =>
            idx > 0 &&
            ["always", "summer"].indexOf(pricePackage.type) > -1 &&
            pricePackage.services &&
            pricePackage.services.length &&
            pricePackage.services[0].prices &&
            pricePackage.services[0].prices.length
        );
      }
      return [];
    },
    winterPrices() {
      return this.poi.pricePackages.filter(
        (pricePackage, idx) =>
          idx > 0 &&
          ["always", "winter"].indexOf(pricePackage.type) > -1 &&
          pricePackage.services &&
          pricePackage.services.length &&
          pricePackage.services[0].prices &&
          pricePackage.services[0].prices.length
      );
    },
    isWinter() {
      return this.currentSeason === "winter";
    },
    showWinterSeason() {
      return this.$store.state.showWinterSeason;
    },

    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    }
  }
};
