import moment from "moment";
import LineChart from "../LineChart/LineChart";

export default {
  name: "PrognoseGraph",
  props: ["prognosis"],
  components: {
    LineChart
  },
  data: () => ({
    chartContainerOverflows: false,
    chartOptions: {
      scales: {
        y: {
          display: true,
          min: 0,
          max: 100,
          grid: {
            display: false
          },
          ticks: {
            stepSize: 33,
            callback: function(value) {
              const labels = {
                33: "Geringe",
                66: "Mittlere",
                100: "Hohe"
              };
              value = parseInt(value);
              return labels[value]?.toUpperCase();
            },
            font: {
              size: 14,
              weight: 600
            }
          }
        },
        x: {
          display: true,
          ticks: {
            align: "center",
            padding: 10,
            font: {
              size: 14,
              weight: 600
            }
          },
          grid: {
            display: false
          }
        }
      },
      maintainAspectRatio: false,
      responsive: false
    }
  }),
  computed: {
    chartData() {
      return {
        labels: this.prognosis.map(prognose => [
          this.getDay(prognose.measurementTime),
          this.formatDate(prognose.measurementTime)
        ]),
        datasets: [
          {
            data: this.prognosis.map(prognose => prognose.value),
            tension: 0.3,
            pointRadius: 0
          }
        ]
      };
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getDay(value) {
      if (this.isToday(value)) {
        return "Heute";
      }
      return moment(value)
        .locale("de")
        .format("dddd");
    },
    isToday(date) {
      return moment(date).isSame(moment.now(), "day");
    },
    setChartContainerOverflows(overflows) {
      this.chartContainerOverflows = overflows;
    }
  }
};
