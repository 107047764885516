import VueHeadful from "vue-headful";
import moment from "moment";
import PictureMixin from "@/mixins/PictureMixin.vue";
import ColorsMixin from "@/mixins/ColorsMixin";
import PricePackagesMixin from "@/mixins/PricePackagesMixin";
import {
  getFeatures,
  getJsonLd,
  getMainPoi,
  getPois,
  getRegionSubPoisOpen
} from "@/helpers/apiHelper";
import {
  decodeHtmlEntities,
  getSettings,
  stripTags
} from "@/helpers/commonHelper";
import {
  apiString,
  checkStep,
  getGoogleRouteLinkForAddress,
  getSkiRegionInfo
} from "@/helpers/detailViewHelper";
import DefaultDetailView from "@/components/DefaultDetailView/DefaultDetailView.vue";
import DetailViewHeader from "@/components/DetailViewHeader/DetailViewHeader.vue";
import FooterIconLegend from "@/components/FooterIconLegend/FooterIconLegend.vue";
import TourDetailViewHeader from "@/components/TourDetailViewHeader/TourDetailViewHeader.vue";
import TourDetailView from "@/components/TourDetailView/TourDetailView.vue";
import Address from "@/components/Address/Address.vue";
import "@/App.sass";
import "./DetailView.sass";

Array.prototype.asyncForEach = async function(callback) {
  for (let index = 0; index < this.length; index++) {
    await callback(this[index], index, this);
  }
};

export default {
  name: "detail-view",
  components: {
    VueHeadful,
    FooterIconLegend,
    TourDetailViewHeader,
    DetailViewHeader,
    DefaultDetailView,
    TourDetailView,
    Address
  },
  mixins: [ColorsMixin, PictureMixin, PricePackagesMixin],
  props: {
    id: {
      type: String,
      default: "eopoi-detail-view"
    },
    poiId: String,
    initialData: [], // might be string or array
    initialSettings: Object,
    initialCategories: undefined,
    initialFeatures: undefined,
    apiKey: String
  },
  data: () => ({
    copyright: "",
    isLoading: true,
    poi: undefined, // poi to show,
    mainPoiIds: [],
    settings: {}, // loaded settings
    featureList: [], // loaded features
    highlightFeatures: [], // loaded features
    error: "",
    widgetWidth: 0, // width of element
    socialShareDialog: false,
    ldJson: undefined,
    readMore: false,
    mobileMap: false,
    refreshMapValue: 0,
    openPanel: null,
    connectedParkingLots: []
  }),
  watch: {
    "$route.params.poiId": async function() {
      window.scrollTo(0, 0);
      await this.loadPoi();
    }
  },
  async mounted() {
    this.$store.commit("SET_SETTINGS", { apiKey: this.apiKey });
    this.widgetWidth = this.$refs.theWidget.offsetWidth;
    await this.$store.dispatch("getCategoryGroups");
    await this.$store.dispatch("getCategoryTypes");
    await this.initialize();
    if (this.poi?.relatedPois?.length) {
      await this.$store.dispatch("getRelatedPois", {
        relatedPoiIds: this.poi.relatedPois
      });

      this.connectedParkingLots = this.relatedPois?.filter(poi =>
        this.checkStep("parkingLot", poi)
      );
    }
    if (this.isOutdoorActiveTour && this.outdoorActiveTourId) {
      window.oa.api.trackDetail(this.outdoorActiveTourId);
    }
    //needed to be reactive
    setTimeout(() => this.mainPoiIds.reverse().reverse(), 1000);
  },
  methods: {
    async initialize() {
      // load categories to Store
      if (this.initialCategories) {
        this.$store.commit("SET_CATEGORIES", this.initialCategories);
      } else {
        await this.$store.dispatch("getCategories");
      }

      if (this.initialFeatures) {
        this.$store.commit("SET_FEATURES", this.initialFeatures);
      } else {
        await this.$store.dispatch("getFeatures");
      }

      // if no settings => get config from API!
      if (this.initialSettings) {
        this.settings = this.initialSettings;
      } else {
        await this.getSettings();
      }

      this.$store.commit("SET_SETTINGS", {
        isWinter: this.currentSeason === "winter"
      });

      await this.loadPoi();
    },
    async loadPoi() {
      this.isLoading = true;
      let loadedFeatures;

      // data defined or get from API?
      if (this.initialData) {
        this.poi =
          typeof this.initialData === "string"
            ? JSON.parse(this.initialData)
            : this.initialData;

        if (this.initialFeatures) loadedFeatures = this.initialFeatures;
      } else {
        await this.loadData();

        // load feature list
        if (this.poi && this.poi.features && this.poi.features.length) {
          loadedFeatures = await getFeatures(
            this.$store.state.apiUrl,
            this.cApiKey
          );
        }
      }

      // parse features
      if (loadedFeatures) {
        loadedFeatures.forEach(featureGroup => {
          if (featureGroup.features) {
            featureGroup.features.forEach(feature => {
              let poiFeatures = this.poi.features.filter(
                f => f?._id === feature._id || f === feature._id
              );

              if (this.hideFeatureWinter && this.winterFeature)
                poiFeatures = poiFeatures.filter(
                  feature => feature !== this.winterFeature
                );
              if (this.hideFeatureSummer && this.summerFeature)
                poiFeatures = poiFeatures.filter(
                  feature => feature !== this.summerFeature
                );
              const customPoiFeature = poiFeatures.find(
                f => typeof f !== "string"
              );
              if (!feature.internalControlFeature && poiFeatures?.length) {
                let featureLabel =
                  this.$store.state.labelsById[feature._id] ||
                  `${feature.title}`;

                if (feature.isHighlight) {
                  if (customPoiFeature?.value) {
                    /** Value is templateString, so array is displayed properly */
                    featureLabel = `${customPoiFeature?.value}`;
                  } else if (this.poi.featureValues?.[feature._id]) {
                    /** Value is templateString, so array is displayed properly */
                    featureLabel = `${this.poi.featureValues?.[feature._id]}`;
                  }
                  this.highlightFeatures.push({
                    label: featureLabel,
                    isHighlight: feature.isHighlight
                  });
                } else {
                  if (customPoiFeature?.value) {
                    featureLabel += `: ${customPoiFeature?.value}`;
                  } else if (this.poi.featureValues?.[feature._id])
                    featureLabel += `: ${this.poi.featureValues[feature._id]}`;
                  this.featureList.push({
                    label: featureLabel,
                    isHighlight: feature.isHighlight
                  });
                }
              }
            });
          }
        });
      }
      this.poi?.regions?.asyncForEach(async regionId => {
        try {
          let mainPoi = await getMainPoi(
            this.$store.state.apiUrl,
            this.$store.state.apiKey,
            regionId
          );
          if (mainPoi) this.mainPoiIds.push(mainPoi);
        } catch (err) {
          console.error(`can't get mainPoi`, err);
        }
      });

      // loading finished
      this.isLoading = false;

      if (
        this.initialData &&
        this.settings &&
        this.settings.apiKey &&
        this.settings.apiKey.displayCurrentlyOpen
      ) {
        // async loading of current opening time to trick caches
        await this.loadData(true);
      }

      // load LD+JSON
      await this.loadLdJson();
    },
    async loadData(openingTimesOnly = false) {
      // define query
      const query = {
        limit: 1,
        hidden: 0
      };

      if (openingTimesOnly) {
        if (!this.poi) {
          this.error = "poi data missing!";
          return;
        }
        query._id = this.poi._id;
      } else {
        if (!this.poiId) {
          this.error = "data-poi attribute missing!";
          return;
        }

        // id is mongo id?
        if (this.poiId.match(/^[0-9a-fA-F]{24}$/)) {
          query._id = this.poiId;
        } else {
          query.slug = this.poiId;
        }
      }

      try {
        const data = await getPois(
          this.$store.state.apiUrl,
          this.cApiKey,
          query
        );

        if (data.data && data.data.length) {
          if (openingTimesOnly) {
            // set poi's opening time only
            this.poi.fuzzyOpenCloseTime = data.data[0].fuzzyOpenCloseTime;
            this.poi.isOpen = data.data[0].isOpen;
            this.poi.opensIn = data.data[0].opensIn;
            this.poi.closesIn = data.data[0].closesIn;
            this.poi.openingHours = data.data[0].openingHours;
          } else {
            // set poi directly
            this.poi = data.data[0];
          }
        } else {
          throw new Error("Konnte die angefragten Daten nicht finden");
        }
        this.poi.skiRegionInfo = await this.getSkiRegionInfo(this.poi._id);
      } catch (e) {
        this.error = e;
      }
    },
    async getSettings() {
      await getSettings.bind(this).call();
    },
    onResize() {
      this.widgetWidth = this.$refs.theWidget?.offsetWidth ?? 0;
    },

    async loadLdJson() {
      this.ldJson = await getJsonLd(
        this.$store.state.apiUrl,
        this.cApiKey,
        this.$store.state.targetUri,
        this.poi?._id
      );
    },
    checkStep(step, poi) {
      return (
        poi && checkStep(step, poi, this.categoryGroups, this.categoryTypes)
      );
    },
    setOpenPanel(value) {
      this.openPanel = value;
    },
    getSkiRegionInfo,
    getRegionSubPoisOpen,
    stripTags
  },
  computed: {
    isCompactView() {
      return (
        this.apiKeyObj.compactDetailView &&
        this.mainPoiIds.some(mPoi => mPoi._id === this.poi._id)
      );
    },
    summerFeature() {
      return this.settings.export?.seasonFeatures?.summer;
    },
    winterFeature() {
      return this.settings.export?.seasonFeatures?.winter;
    },
    hideFeatureSummer() {
      return this.settings.apiKey.hideFeatureSummer;
    },
    hideFeatureWinter() {
      return this.settings.apiKey.hideFeatureWinter;
    },
    isFavorite() {
      return (
        this.$store.state.favorites &&
        this.$store.state.favorites.includes(this.poi?._id)
      );
    },
    isOutdoorActiveTour() {
      return typeof window.oa !== "undefined";
    },
    outdoorActiveTourId() {
      if (!this.isOutdoorActiveTour) {
        return;
      }
      return this.poi.additionalData.find(
        data => data.typeId === "OutdoorActive-ID"
      )?.value;
    },
    shortDescription() {
      if (this.poi) {
        const isWinter = this.currentSeason === "winter";

        if (
          isWinter &&
          this.poi.shortDescription &&
          this.poi.shortDescription.seasonalDescription
        )
          return decodeHtmlEntities(
            stripTags(this.poi.shortDescription.seasonalDescription)
          );
        if (this.poi.shortDescription?.description)
          return decodeHtmlEntities(
            stripTags(this.poi.shortDescription.description)
          );
      }

      return "";
    },
    description() {
      if (this.poi) {
        const isWinter = this.currentSeason === "winter";

        if (
          isWinter &&
          this.poi.description &&
          this.poi.description.seasonalDescription
        )
          return this.poi.description.seasonalDescription;
        if (this.poi.description?.description)
          return this.poi.description.description;
      }

      return "";
    },
    currentMessage() {
      if (
        this.poi &&
        this.poi.currentMessage &&
        this.poi.currentMessage.message
      ) {
        const now = moment();

        const begin = this.poi.currentMessage.begin
          ? moment(this.poi.currentMessage.begin)
              .subtract(now.utcOffset(), "minutes")
              .unix()
          : 0;
        const end = this.poi.currentMessage.end
          ? moment(this.poi.currentMessage.end)
              .subtract(now.utcOffset(), "minutes")
              .add(1, "d")
              .unix()
          : 9999999999; // added one day, because it is inclusive
        const unixTime = now.unix();

        // only return if really current!
        if (begin <= unixTime && unixTime <= end)
          return this.poi.currentMessage.message;
      }
      return "";
    },
    hygieneNotice() {
      return this.poi?.description?.hygieneNoticeText;
    },
    hygieneLink() {
      return this.poi?.description?.hygieneNoticeIUrl ?? false;
    },
    poiImages() {
      return this.poi ? this.camAndSeasonImages(this.poi) : [];
    },
    panoramaMaps() {
      return this.poiImages
        .filter(i => i?.customTypes?.includes("Panoramakarte"))
        .map(m => {
          m.smallUrl = m.url?.replace("/pois/", "/t/600x400/pois/");
          return m;
        });
    },
    socialMediaKeys() {
      if (this.poi && this.poi.online) {
        return Object.keys(this.poi.online).filter(key => this.poi.online[key]);
      }
      return [];
    },
    routeAddress() {
      return this.poi && this.poi.location
        ? getGoogleRouteLinkForAddress(this.poi.address)
        : undefined;
    },
    mainPois() {
      let cats = [];
      this.mainPoiIds.forEach(p => {
        //ignore mainPoi of mainPoi
        if (this.poi._id === p._id) return;

        let catLabel = this.$store.state?.labelsById?.[p.category];
        cats.push({
          catLabel,
          title: p.title,
          slug: p.slug
        });
      });
      return cats;
    },
    categories() {
      // collect renamed categories
      const categories = [];

      if (this.poi) {
        if (
          this.poi.category &&
          this.$store.state.labelsById[this.poi.category]
        ) {
          categories.push(this.$store.state.labelsById[this.poi.category]);
        }
        if (
          this.poi.additionalCategories &&
          this.poi.additionalCategories.length
        ) {
          this.poi.additionalCategories.forEach(category => {
            if (this.$store.state.labelsById[category]) {
              categories.push(this.$store.state.labelsById[category]);
            }
          });
        }
      }

      return categories.length ? categories : null;
    },
    myURL() {
      return encodeURIComponent(window.location.href);
    },
    firstPicture() {
      const pictures =
        (this.poi && this.filterSeasonImages(this.poi.pictures)) || [];
      return pictures && pictures.length && pictures[0];
    },
    apiString,
    listUri() {
      return this.$store.state.listUri
        ? this.$store.state.listUri + this.apiString + "#eopoi-list"
        : "/pois";
    },
    summaryUri() {
      return this.$store.state.summaryUri
        ? `${this.$store.state.summaryUri + this.apiString}`
        : "/summary";
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    relatedPois() {
      return this.$store.state.relatedPois;
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    hideCopyright() {
      return !!this.settings.apiKey?.hideCopyright;
    },
    ownCopyright() {
      if (this.settings.apiKey?.ownCopyright !== "") {
        return this.settings.apiKey?.ownCopyright;
      } else return "Made with ♥ EO Heimat / OYA media";
    },
    ownCoprightUrl() {
      if (this.settings.apiKey?.ownCoprightUrl !== "") {
        return this.settings.apiKey?.ownCoprightUrl;
      } else return "https://www.oya-media.de/";
    },
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    },
    cApiKey() {
      return this.apiKey || this.$store.state.apiKey;
    },
    showNotepad() {
      return this.settings.apiKey.showNotepad;
    }
  }
};
