import "./ExcursionTicker.scss";
import PrognoseGraph from "@/components/PrognoseGraph/PrognoseGraph.vue";
import moment from "moment";

export default {
  name: "ExcursionTicker",
  props: ["excursionTicker"],
  components: {
    PrognoseGraph
  },
  data: () => ({
    readMore: false,
    readMoreMinParagraphsLength: 2,
    maxDaysToDisplay: 7
  }),
  methods: {
    containsPrognosis() {
      if (!this.prognosis.length) {
        return false;
      }
      return ["Auslastung", "Ausflugstipps"].includes(
        this.excursionTicker.notificationType
      );
    },
    isValidToDisplay() {
      if (!this.excursionTicker || !this.excursionTicker.validity) {
        return false;
      }
      return this.isTodayOrAfterToday(this.excursionTicker.validity);
    },
    isTodayOrAfterToday(value) {
      return moment(value).isSameOrAfter(moment.now(), "day");
    },
    isOutdatedPrognose(prognose) {
      return !this.isTodayOrAfterToday(
        moment(prognose.measurementTime).add(this.maxDaysToDisplay, "day")
      );
    }
  },
  computed: {
    prognosis() {
      const prognosis = this.excursionTicker.prognosis.filter(
        prognose => !this.isOutdatedPrognose(prognose)
      );
      if (prognosis.length <= this.maxDaysToDisplay) {
        return prognosis;
      }
      return prognosis.slice(-this.maxDaysToDisplay);
    },
    descriptionParagraphs() {
      const div = document.createElement("div");
      div.innerHTML = this.excursionTicker.description;
      const paragraphs = div.querySelectorAll("p");
      div.remove();
      return [...paragraphs];
    },
    descriptionHTML() {
      if (
        this.readMore ||
        this.descriptionParagraphs.length <= this.readMoreMinParagraphsLength
      ) {
        return this.excursionTicker.description;
      }
      return this.descriptionParagraphs
        .slice(0, this.readMoreMinParagraphsLength)
        .map(paragraph => paragraph.outerHTML)
        .join("");
    }
  }
};
