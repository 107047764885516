var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"mapWrapper"},[_c('l-map',{ref:"myDetailMap",staticStyle:{"width":"100%"},style:({ height: _vm.height + 'px' }),attrs:{"minZoom":5,"maxZoom":18,"maxBounds":[
      [43, 3],
      [57, 20]
    ],"zoom":14,"center":{ lat: _vm.poi.geo.lat, lng: _vm.poi.geo.lng },"options":{
      tap: false,
      dragging: true,
      doubleClickZoom: false
    }},on:{"ready":_vm.mapLoaded}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),(_vm.gpxXml)?_c('LGpx',{attrs:{"gpxFile":_vm.gpxXml,"gpxOptions":{
        async: true,
        marker_options: {
          endIconUrl: _vm.pinIconPath,
          startIconUrl: _vm.pinIconPath,
          shadowUrl: ''
        }
      }},on:{"gpxLoaded":e => _vm.gpxFitBounds(e)}}):_vm._e(),(!_vm.gpxUrl)?_c('MapIcon',{attrs:{"lat-lng":{ lat: _vm.poi.geo.lat, lng: _vm.poi.geo.lng },"active":_vm.isMapIconActive,"poi":_vm.poi},on:{"mouseenter":function($event){_vm.isMapIconActive = true},"mouseleave":function($event){_vm.isMapIconActive = false}}},[(_vm.poi && _vm.poi.address)?_c('l-popup',[_c('Address',{attrs:{"address":_vm.poi.address,"settings":_vm.settings,"full":false,"altTitle":""}})],1):_vm._e()],1):_vm._e(),(_vm.connectedParkingLots && _vm.connectedParkingLots.length)?_c('div',_vm._l((_vm.connectedParkingLots),function(parkingLot){return _c('MapIcon',{key:parkingLot._id,attrs:{"lat-lng":{ lat: parkingLot.geo.lat, lng: parkingLot.geo.lng },"isParkingLot":true,"stroke":_vm.getColorForParkingLot(parkingLot),"fill":parkingLot.state.state !== 'closed'
            ? _vm.getColorForParkingLot(parkingLot)
            : 'transparent'}},[(parkingLot && parkingLot.address)?_c('l-popup',[_c('Address',{attrs:{"address":parkingLot.address,"settings":_vm.settings,"full":false,"altTitle":""}})],1):_vm._e()],1)}),1):_vm._e(),(_vm.street || _vm.routeAddress)?_c('l-control',{attrs:{"position":"bottomleft"}},[_c('div',{staticClass:"eopoi-detail-map-box"},[(_vm.street)?_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.street))]):_vm._e(),(_vm.routeAddress)?_c('div',[_c('a',{attrs:{"href":'https://maps.google.com/maps?daddr=' + _vm.routeAddress,"target":"_blank"}},[_vm._v(" Routenplaner "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-chevron-right")])],1)]):_vm._e()])]):_vm._e(),_c('l-control',{attrs:{"position":"topright"}},[_c('div',{staticClass:"eopoi-detail-map-fullscreen",attrs:{"title":_vm.isFullScreen ? 'Karte verkleinern' : 'Karte vergrößeren'}},[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.fullScreenToggle}},[_c('v-icon',{attrs:{"size":32}},[_vm._v(_vm._s(_vm.isFullScreen ? "mdi-fullscreen-exit" : "mdi-fullscreen")+" ")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }