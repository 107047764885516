import "./TourDescriptionTabs.scss";

export default {
  props: ["poi", "description", "currentMessage"],
  data: () => ({
    activeDescriptionTab: "0"
  }),
  mounted() {
    this.cloneTabSliderForBackground();
  },
  computed: {
    descriptionTab() {
      const descriptions = {
        title: "Beschreibung",
        contents: [
          {
            contents: this.description
          },
          {
            title: "Ausrüstung",
            contents: this.poi.route?.equipment
          },
          {
            title: "Weitere Infos / Links",
            contents: this.poi.route?.additionalInformation
          }
        ]
      };

      if (this.tourDetailDataSourceHTML?.length) {
        descriptions.contents.push({
          contents: this.tourDetailDataSourceHTML
        });
      }
      return descriptions;
    },
    routeTab() {
      if (!this.poi.route?.description) {
        return {
          title: "Wegbeschreibung",
          contents: []
        };
      }
      return {
        title: "Wegbeschreibung",
        contents: [
          {
            title: "Startpunkt der Tour",
            contents: this.poi.route.description.startingPointDescr
          },
          {
            title: "Zielpunkt der Tour",
            contents: this.poi.route.description.destination
          },
          {
            title: "Navigationsbeschreibung",
            contents: this.poi.route.description.directions
          },
          {
            title: "Sicherheitshinweise",
            contents: this.poi.route.description.safetyGuidelines
          },
          {
            title: "Wegkennzeichen",
            contents: this.poi.route.description.trackSignage
          }
        ]
      };
    },
    arrivalTab() {
      const tabObj = {
        title: "Anreise",
        contents: [
          {
            title: "Öffentliche Verkehrsmittel",
            contents: this.poi.route?.description?.publicTransit
          },
          {
            title: "Anfahrt",
            contents: this.poi.description?.arrivalInformation
          },
          {
            title: "Parken",
            contents: this.poi.route?.description?.parking
          }
        ]
      };
      if (this.poi.geo?.lat && this.poi.geo?.lng) {
        tabObj.contents.push({
          title: "Koordinaten Startpunkt",
          contents: `Breitengrad: ${this.poi.geo.lat}, Längengrad: ${this.poi.geo.lng}`
        });
      }
      return tabObj;
    },
    currentMessageTab() {
      return {
        title: "Aktuelle Nachricht",
        contents: [
          {
            title: "Aktuelle Nachricht",
            contents: this.currentMessage
          }
        ]
      };
    },
    allTabs() {
      const tabs = [
        this.descriptionTab,
        this.routeTab,
        this.arrivalTab,
        this.currentMessageTab
      ];
      return tabs
        .filter(tab => this.isValidTabContents(tab))
        .map(tab => ({
          title: tab.title,
          contents: this.getTabContentsHTML(tab.contents)
        }));
    },
    importMetaData() {
      return this.poi.importMetaData;
    },
    tourDetailDataSourceHTML() {
      if (!this.importMetaData?.source) {
        return "";
      }
      const logo = this.importMetaData.source.logo;
      const source = this.importMetaData.source?.name;
      const author = this.importMetaData.author;

      return `
        <div id="tour_detail_data_source___wrapper" class="mt-14">
          <v-row cols="12" class="mt-sm-3 mt-lg-6">
            <v-col cols="12" class="py-0 my-0">
              <img style="height: 3em;" src="${logo.url}" alt="${logo.title}" aria-label="${logo.title}" />
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <p>
                <b
                  >Die Tour wird präsentiert von: ${source}, Autor: ${author}</b
                >
              </p>
            </v-col>
          </v-row>
      </div>`;
    }
  },
  methods: {
    cloneTabSliderForBackground() {
      const tabSlider = document.querySelector(".v-tabs-slider-wrapper");
      if (tabSlider) {
        tabSlider.parentNode.insertBefore(tabSlider.cloneNode(true), tabSlider);
      }
    },
    isValidTabContents(tabObj) {
      return tabObj.contents.some(contentsObj => contentsObj.contents?.length);
    },
    getTabContentsHTML(contents) {
      return contents.reduce((prev, curr) => {
        if (!curr.contents?.length) {
          return prev;
        }

        const title = curr.title
          ? `<p class="mt-2 mb-0"><b class="tab-title">${curr.title}</b></p>`
          : "";
        const contents = curr.contents
          ? `<p class="my-0">${curr.contents}</p>`
          : "";
        return `${prev}${title}${contents}`;
      }, "");
    }
  }
};
