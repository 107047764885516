var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.address)?_c('div',[(_vm.full)?_c('div',[(_vm.address.name)?_c('span',[_vm._v(_vm._s(_vm.address.name))]):(_vm.altTitle)?_c('span',[_vm._v(_vm._s(_vm.altTitle))]):_vm._e(),_c('br')]):_vm._e(),(_vm.address.address)?_c('span',[_vm._v(" "+_vm._s(_vm.address.address)+" "),_c('br')]):_vm._e(),(_vm.address.additionalAddress)?_c('span',[_vm._v(" "+_vm._s(_vm.address.additionalAddress)+" "),_c('br')]):_vm._e(),(
      _vm.settings.apiKey.displayLocationCity &&
        (_vm.address.zip || _vm.address.city || _vm.address.district)
    )?_c('span',[(_vm.address.zip)?_c('span',[_vm._v(_vm._s(_vm.address.zip)+" ")]):_vm._e(),(_vm.address.city)?_c('span',[_vm._v(_vm._s(_vm.address.city)+" ")]):_vm._e(),(_vm.address.district && _vm.settings.apiKey.displayLocationDistrict)?_c('span',[_vm._v(" - "+_vm._s(_vm.address.district)+" ")]):_vm._e(),_c('br')]):_vm._e(),(_vm.settings.apiKey.displayLocationCountry && _vm.address.address.country)?_c('span',[_vm._v(" "+_vm._s(_vm.address.country)+" "),_c('br')]):_vm._e(),(_vm.full)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeAllTooltips),expression:"closeAllTooltips"}]},[(_vm.address.phoneNumber)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.mobilePhoneTooltipContent,
        show: _vm.isPhoneOpen,
        trigger: 'manual'
      }),expression:"{\n        content: mobilePhoneTooltipContent,\n        show: isPhoneOpen,\n        trigger: 'manual'\n      }"}],staticClass:"mr-3 mt-4",attrs:{"rounded":"","icon":"","elevation":"2"},on:{"click":function($event){return _vm.openTooltip('isPhoneOpen')}}},[_c('v-icon',[_vm._v("mdi-phone")])],1):_vm._e(),(_vm.address.mobileNumber)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.mobileCellphoneTooltipContent,
        show: _vm.isCellphoneOpen,
        trigger: 'manual'
      }),expression:"{\n        content: mobileCellphoneTooltipContent,\n        show: isCellphoneOpen,\n        trigger: 'manual'\n      }"}],staticClass:"mr-3 mt-4",attrs:{"rounded":"","icon":"","elevation":"2"},on:{"click":function($event){return _vm.openTooltip('isCellphoneOpen')}}},[_c('v-icon',[_vm._v("mdi-cellphone")])],1):_vm._e(),(_vm.address.eMail || _vm.address.contactEMail)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.eMailTooltipContent,
        show: _vm.isEmailOpen,
        trigger: 'manual'
      }),expression:"{\n        content: eMailTooltipContent,\n        show: isEmailOpen,\n        trigger: 'manual'\n      }"}],staticClass:"mr-3 mt-4",attrs:{"rounded":"","icon":"","elevation":"2"},on:{"click":function($event){return _vm.openTooltip('isEmailOpen')}}},[_c('v-icon',[_vm._v("mdi-email")])],1):_vm._e(),(_vm.address.website)?_c('v-btn',{staticClass:"mt-4",attrs:{"href":_vm.fixUrl(_vm.address.website),"rounded":"","icon":"","elevation":"2","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-web")])],1):_vm._e()],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }