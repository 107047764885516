import moment from "moment";
import "./PoiHoursTable.sass";

export default {
  name: "PoiHoursTable",
  props: {
    openingHours: Array // weekday array
  },
  computed: {
    // compute list entries
    listEntries() {
      if (this.openingHours && this.openingHours.length) {
        // every day the same?
        const hourPermutations = [];
        this.openingHours.forEach(hour => {
          const value = JSON.stringify(hour.times);
          if (!hourPermutations.includes(value)) hourPermutations.push(value);
        });
        if (hourPermutations.length === 1) {
          // daily
          return [
            {
              day: "Täglich",
              ...this.openingHours[0]
            }
          ];
        }
        moment.locale("de");
        const days = moment.weekdays();
        days.push(days.splice(0, 1)[0]); // move sunday to end of list
        return this.openingHours.map((openingHour, idx) => {
          return {
            day: days[idx],
            ...openingHour
          };
        });
      }
      return "";
    }
  }
};
