import {
  mToKm,
  getConditionText,
  getLocationText,
  getSlopeCondition,
  getSnowTexture,
  toDate,
  arrayIsEmpty,
  enumArrayToString
} from "@/helpers/commonHelper.js";
import "./TourDetailStatus.scss";

export default {
  props: ["state"],
  methods: {
    mToKm,
    getConditionText,
    getLocationText,
    getSlopeCondition,
    getSnowTexture,
    toDate,
    arrayIsEmpty,
    enumArrayToString
  }
};
