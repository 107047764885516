import PoiPrices from "@/components/PoiPrices/PoiPrices.vue";
import PoiHours from "@/components/PoiHours/PoiHours.vue";
import { fixUrl, stripTags, decodeHtmlEntities } from "@/helpers/commonHelper";
import * as detailViewHelper from "@/helpers/detailViewHelper.js";
import PictureMixin from "@/mixins/PictureMixin.vue";
import ColorsMixin from "@/mixins/ColorsMixin.vue";
import PricePackagesMixin from "@/mixins/PricePackagesMixin.vue";
import PoiRegionSubjects from "@/components/PoiRegionSubjects/PoiRegionSubjects.vue";
import PoiDetailStatus from "@/components/PoiDetailStatus/PoiDetailStatus.vue";
import ExcursionTicker from "@/components/ExcursionTicker/ExcursionTicker.vue";
import Features from "@/components/Features/Features.vue";
import SocialIcons from "@/components/SocialIcons/SocialIcons.vue";
import RelatedPoisTeaser from "@/components/RelatedPoisTeaser/RelatedPoisTeaser.vue";

import {
  checkStep,
  getColorForParkingLot,
  navigate
} from "@/helpers/detailViewHelper.js";
import "./DefaultDetailView.scss";
import PanoramaMaps from "@/components/PanoramaMaps/PanoramaMaps.vue";

export default {
  props: [
    "poi",
    "isCompactView",
    "hygieneNotice",
    "hygieneLink",
    "shortDescription",
    "featureList",
    "highlightFeatures",
    "currentMessage",
    "currentSeason",
    "description",
    "panoramaMaps",
    "listUri",
    "mainPois",
    "settings",
    "openPanel",
    "connectedParkingLots"
  ],
  components: {
    PoiHours,
    PoiPrices,
    PoiRegionSubjects,
    PanoramaMaps,
    PoiDetailStatus,
    ExcursionTicker,
    Features,
    SocialIcons,
    RelatedPoisTeaser
  },
  mixins: [ColorsMixin, PictureMixin, PricePackagesMixin],
  methods: {
    ...detailViewHelper,
    decodeHtmlEntities,
    stripTags,
    fixUrl,
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    hasOpeningTimeInfo(openingHours) {
      let winterDescriptionActive = "";
      if (this.currentSeason === "winter") {
        winterDescriptionActive =
          openingHours?.description?.seasonalDescription;
      }
      const activeWeekdays = openingHours?.weekdays?.some(weekday => {
        return (
          weekday?.active &&
          weekday?.times?.some(time => {
            return time?.to || time?.from;
          })
        );
      });
      const oneDay = 1000 * 60 * 60 * 24;
      const activeSeasonalOpeningHours = openingHours?.seasonalOpeningHours.some(
        timespan =>
          timespan.endDate &&
          new Date(timespan.endDate).getTime() + oneDay >= new Date().getTime()
      );
      const activeSpecialDays = openingHours?.specialDays.some(
        day =>
          day.date &&
          new Date(day.date).getTime() + oneDay >= new Date().getTime()
      );

      return (
        winterDescriptionActive ||
        openingHours?.description?.description ||
        activeWeekdays ||
        activeSeasonalOpeningHours ||
        activeSpecialDays
      );
    },
    linkToParkingLot(slug) {
      if (slug) window.location.assign(slug);
    },
    getColorForParkingLot,
    navigate
  },
  data: () => ({
    readMore: false
  }),
  computed: {
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    hideFeatureSummer() {
      return this.settings.apiKey.hideFeatureSummer;
    },
    hideFeatureWinter() {
      return this.settings.apiKey.hideFeatureWinter;
    },
    cOpenPanel: {
      get() {
        return this.openPanel;
      },
      set(value) {
        this.$emit("openPanelChanged", value);
      }
    }
  }
};
