import "./TourDetailTip.scss";

export default {
  props: ["tipHtml"],
  computed: {
    tipText() {
      const container = document.createElement("div");
      container.innerHTML = this.tipHtml;
      return container.innerText;
    }
  }
};
