export default {
  name: "PoiPricesPiece",
  props: {
    pricePackage: Object,
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getServices() {
      if (this.pricePackage.services && this.pricePackage.services.length) {
        return this.pricePackage.services.filter(service => !!service.name);
      }
      return [];
    }
  },
  methods: {
    hasReducedPrices(prices) {
      if (prices && prices.length) {
        for (let i = 0; i < prices.length; i++) {
          if (prices[i].altPrice) return true;
        }
      }

      return false;
    }
  }
};
