export default {
  props: [
    "featureList",
    "highlightFeatures",
    "hideFeatureWinter",
    "hideFeatureSummer"
  ],
  computed: {
    showHighlightFeatures() {
      return this.highlightFeatures?.length;
    },
    showFeatures() {
      return this.cFeatureList?.length;
    },
    cFeatureList() {
      let featureList = [...this.featureList];
      featureList = featureList.filter(
        feature => feature.label !== "Hütten-Typ: "
      );
      if (this.hideFeatureWinter) {
        featureList = featureList.filter(
          feature => feature.label.toLowerCase() !== "winter"
        );
      }
      if (this.hideFeatureSummer) {
        featureList = featureList.filter(
          feature => feature.label.toLowerCase() !== "sommer"
        );
      }
      return featureList;
    }
  }
};
