import { fixUrl } from "@/helpers/commonHelper";
import VTooltip from "v-tooltip";
import Vue from "vue";
import "@/styles/v-tooltip.scss";
import ClickOutside from "vue-click-outside";
Vue.use(VTooltip);

export default {
  props: {
    address: { type: Object, default: null },
    settings: { type: Object, default: {} },
    full: { type: Boolean, default: true },
    altTitle: { type: String, default: "" }
  },
  data: () => ({
    isEmailOpen: false,
    isCellphoneOpen: false,
    isPhoneOpen: false
  }),
  methods: {
    fixUrl,
    openTooltip(key) {
      const value = !this[key];
      this.closeAllTooltips();
      this[key] = value;
    },
    closeAllTooltips() {
      this.isCellphoneOpen = false;
      this.isEmailOpen = false;
      this.isPhoneOpen = false;
    }
  },
  computed: {
    mobilePhoneTooltipContent() {
      return `<a href="tel:${this.address?.phoneNumber}">${this.address?.phoneNumber}</a>`;
    },
    mobileCellphoneTooltipContent() {
      return `<a href="tel:${this.address?.mobileNumber}">${this.address?.mobileNumber}</a>`;
    },
    eMailTooltipContent() {
      return `<a href="mailto:${this.address?.eMail ||
        this.address?.contactEMail}">${this.address?.eMail ||
        this.address?.contactEMail}</a>`;
    }
  },
  directives: {
    ClickOutside
  }
};
