import axios from "axios";
import TeaserCard from "@/components/TeaserCard/TeaserCard.vue";
import { apiString } from "@/helpers/detailViewHelper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "./RelatedPoisTeaser.sass";

export default {
  name: "RelatedPoisTeaser",
  props: ["relatedPois", "settings"],
  components: { TeaserCard, SwiperSlide, Swiper },
  data: () => ({
    model: null,
    rPois: [],
    swiperOption: {
      breakpoints: {
        1290: {
          slidesPerView: 6,
          spaceBetween: 10
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      },
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    }
  }),
  async mounted() {
    await this.loadPois();
  },
  methods: {
    async loadPois() {
      if (this.relatedPois?.length) {
        const { data } = await axios.get(
          `${this.$store.state.apiUrl}poi/relatedPois`,
          {
            params: {
              apiKey: this.settings?.apiKey.apiKey,
              relatedPois: this.relatedPois
            }
          }
        );
        this.rPois = data;
      }
    },
    resizePicture(url) {
      const isOutdoorActiveImage = url.includes("img.oastatic");
      if (isOutdoorActiveImage) {
        // Use same values as in PoiPicture for caching and to prevent requesting bigger sizes as the original is due unknown dimensions.
        const widhtOutdoorActive = 1280;
        const heightOutdoorActive = 720;
        return url.replace(
          "/img/",
          `/img/${widhtOutdoorActive}/${heightOutdoorActive}/`
        );
      }
      const width = 500;
      return url.replace(/\/pois\//, `/t/${width}/pois/`);
    },
    firstPicture(poi) {
      let imageSrc = "";
      if (poi?.pictures?.length && poi?.pictures[0].url)
        imageSrc = this.resizePicture(poi.pictures[0].url);
      else if (this.settings?.apiKey?.fallbackPicture?.url)
        return this.settings.apiKey.fallbackPicture.url;
      return imageSrc;
    },
    href(poi) {
      if (this.$store.state.targetUri) {
        // replace place holder strings with meaningful stuff
        return this.$store.state.targetUri.replace(
          /xxxSLUGxxx/,
          (poi.slug || poi._id) + this.apiString
        );
      }
      return "";
    }
  },
  computed: {
    apiString,
    targetUri() {
      return this.$store.state.targetUri;
    }
  }
};
