import PoiSub from "@/components/PoiSub/PoiSub";
import moment from "moment";

export default {
  extends: PoiSub,
  methods: {
    trackType(p) {
      if (p.state.trackType === "classicAndSkating") return "Klassisch/Skating";
      if (p.state.trackType === "classic") return "Klassisch";
      if (p.state.trackType === "skating") return "Skating";
      return "k.A.";
    },
    getTrailCondition(p) {
      if (p.state.slopeCondition === "veryGood") return "Sehr gut";
      else if (p.state.slopeCondition === "good") return "Gut";
      else if (p.state.slopeCondition === "okay") return "Befriedigend";
      else if (p.state.slopeCondition === "bad") return "Schlecht";
    },
    hasTrailCondition(p) {
      return p.state.slopeCondition;
    },
    toDate(date) {
      return moment(date).format("DD.MM.YYYY");
    }
  }
};
