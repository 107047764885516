import { fixUrl } from "@/helpers/commonHelper.js";
import "./SocialIcons.scss";

export default {
  props: ["online"],
  methods: {
    fixUrl
  },
  computed: {
    socialIcons() {
      if (!this.online) {
        return;
      }
      const socialIcons = [
        {
          url: this.online.instagram,
          text: "Instagram",
          icon: "mdi-instagram"
        },
        {
          url: this.online.facebook,
          text: "Facebook",
          icon: "mdi-facebook"
        },
        {
          url: this.online.youtube,
          text: "YouTube",
          icon: "mdi-youtube"
        },
        {
          url: this.online.wikipedia,
          text: "Wikipedia",
          icon: "mdi-wikipedia"
        },
        {
          url: this.online.twitter,
          text: "Twitter",
          icon: "mdi-twitter"
        },
        {
          url: this.online.video,
          text: "Video",
          icon: "mdi-video-outline"
        },
        {
          url: this.online.flickr,
          text: "Flickr"
        }
      ];
      return socialIcons.filter(social => !!social.url?.trim().length);
    }
  }
};
