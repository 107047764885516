import moment from "moment";
import PoiHoursTable from "@/components/PoiHoursTable/PoiHoursTable.vue";

export default {
  name: "PoiHours",
  components: { PoiHoursTable },
  props: {
    poi: {
      type: Object,
      required: true
    }
  },
  computed: {
    openingHoursDescription() {
      if (
        this.poi &&
        this.poi.openingHours &&
        this.poi.openingHours.description
      ) {
        if (
          this.$store.state.isWinter &&
          this.poi.openingHours.description.seasonalDescription
        ) {
          return this.poi.openingHours.description.seasonalDescription;
        }
        return this.poi.openingHours.description.description;
      }

      return "";
    },
    generalOpeningTimes() {
      let openDays = 0;

      if (this.poi && this.poi.openingHours && this.poi.openingHours.weekdays) {
        this.poi.openingHours.weekdays.forEach(openingTime => {
          if (openingTime && openingTime.active) openDays++;
        });
      }

      if (openDays) return this.poi.openingHours.weekdays;
      return null;
    },
    seasonalOpeningTimes() {
      if (
        this.poi &&
        this.poi.openingHours &&
        this.poi.openingHours.seasonalOpeningHours &&
        this.poi.openingHours.seasonalOpeningHours.length
      ) {
        const now = moment().subtract(7, "d"); // filter out days passed - allow 7 day old entries to remain

        // filter old seasonal entries
        return this.poi.openingHours.seasonalOpeningHours.filter(
          seasonalOpeningHour => {
            if (
              seasonalOpeningHour &&
              seasonalOpeningHour.startDate &&
              seasonalOpeningHour.endDate
            ) {
              const then = moment(seasonalOpeningHour.endDate);
              return now < then;
            }
            return false;
          }
        );
      }
      return null;
    },
    closedDays() {
      if (
        this.poi &&
        this.poi.openingHours &&
        this.poi.openingHours.closedDays &&
        this.poi.openingHours.closedDays.length
      ) {
        const now = moment().subtract(7, "d"); // filter out days passed - allow 7 day old entries to remain

        // filter old closed days
        return this.poi.openingHours.closedDays.filter(closedDay => {
          if (closedDay && closedDay.endDate) {
            const then = moment(closedDay.endDate);
            return now < then;
          }
          return false;
        });
      }
      return null;
    },
    specialDays() {
      if (
        this.poi &&
        this.poi.openingHours &&
        this.poi.openingHours.specialDays &&
        this.poi.openingHours.specialDays.length
      ) {
        const now = moment().subtract(7, "d"); // filter out days passed - allow 7 day old entries to remain

        // filter old closed days
        return this.poi.openingHours.specialDays.filter(specialDay => {
          if (specialDay && specialDay.date) {
            const then = moment(specialDay.date);
            return now < then;
          }
        });
      }
      return null;
    }
  },
  methods: {
    fullDate(dateString) {
      const d = moment(dateString);
      d.locale("de");

      return d.format("LL");
    }
  }
};
